.portfolio-view {
    .action-bar {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        div.action-bar-criteria {
            display: flex;
            margin-left: 1.5rem;
        }

        .form-group {
            margin-bottom: 0;
        }

        .right-button-bar {
            flex: auto;
            text-align: right;
        }
    }

    .calculate-button {
        .dropdown-menu {
            .dropdown-item {
                line-height: 1.1;
                white-space: initial;
                padding: 0.5rem 1.5rem;
                width: 300px;

                .text-muted {
                    font-size: 13px;
                }
            }
        }
    }

    label {
        margin-bottom: 0.25rem;
    }
    .custom-radio label {
        margin-bottom: 0;
    }

    .inline-field {
        label {
            margin-bottom: 0;
            color: inherit;
        }

        &.sub {
            font-weight: 100;
            font-size: 13px;
        }
    }

    .trade-component {
        table.table {
            tr.linked-Row > td {
                border-top: none;
            }

            tr.trade-group-footer > td {
                border-top: none;
            }
        }
    }

    .flex-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    table.hidden-fields {
        width: 100%;
    }

    .price-details {
        display: inline-block;
        vertical-align: middle;
    }

    .table-field-container {
        width: 300px;
    }
    .calculator-profit-column {
        width: calc((300px + 30px) / 12 * 5);
    }

    .portfolio-group-footer {
        .conversion-details {
            line-height: 1;
            font-size: 12px;
            font-weight: 100;
        }
    }

    .tranche-child-row {
        max-width: 1160px;
        overflow-x: auto;
    }
}

.trade-terms {
    max-height: 600px;
    overflow: auto;
}

.info-btn{
    color: #00B5DC;
    border-color: transparent;
}

.limit-price-confirm-box {
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    .btn {
        width: 100%;
    }

    p {
        margin: 0.5rem 0;
        font-size: 15px;
    }
    p.font-light {
        font-weight: 100;
    }

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

@media (max-width: 991.98px) {
    .portfolio-view {
        div.action-bar {
            div.action-bar-criteria {
                order: 1;
                margin-top: 1rem;
                flex-basis: 100%;
                margin-left: 0;
            }
        } 
    }
}

@media (max-width: 767.98px) {
    .portfolio-view {
        .description-column {
            .inline-field {
                div:nth-child(2) {
                    text-align: right;
                }
            }
        }
    }
}