@import "./my-awards.scss";
@import "./accept-awards.scss";
@import "./release.scss";
@import "./allocation-managers.scss";

.footer-banner {
    display: flex;
    justify-content: space-between;
    
    min-height: 87px;
    width: 100%;

    padding: 0px 10px;

    .content-holder {
        margin: auto 5px;
        width: 50%;
    }

    .message-holder {
        text-align: right;
        font-size: 12px;
        font-weight: 200;

        margin: auto 5px;
        width: 50%;

        .message {
            width: 100%;
        }
    }
}

.awards-grid {
    thead {
        tr {
            th {
                button.btn {
                    padding: 0px;
                }
            }
        }
    }
    tbody {
        background-color: white;
        
        tr {
            &.no-border {
                td {
                    border-top: none;
                }
            }

            &.dashed-border {
                td {
                    border-top-style: dashed;
                }
            }

            td {
                &.hidden {
                    display: none;
                }

                &.max-100 {
                    max-width: 100px;
                    
                    &.nowrap {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                &.is-selected {
                    padding-top: 10px;
                    font-size: 18px;
                }

                &.calc-failure {
                    font-style: italic;
                    color: red;
                }
            }
        }
    }
}