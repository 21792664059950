@import "../../../App/Styles/Variable/Variables.scss";

.approval-history {
    .neo-grid {
      td.changed {
        color: $bs-info;
      }
    }
  }

  .filter-buttons {
    .btn {
      height: 39px;
      width: 39px;
      margin: 0px 3px;
    }
    margin-bottom: 15px;

    .filter-stats {
      padding-left: 10px;
    }
  }