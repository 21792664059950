.award-release {

    .pager-text-filter {
        label {
            font-weight: 500;
        }
    }
   
    .release-buttons {
        padding: 10px 10px 10px 10px;
        display: grid;
        grid-auto-flow: column;

        .btn {
            min-width: 130px;
        }
    }
}

.participant-awards-modal {
    .modal-content {
        form {
            h4 {
                font-weight: 400;
            }
            .modal-footer {
                // justify-content: space-between !important; // This gets the buttons the same as the design, but looks strange with more than 2 buttons.

                .btn {
                    min-width: 130px;
                }
            }
        }
    }
}


