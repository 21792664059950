//General colors
$offWhite: #f7f7f7;
$offGrey: #ecefef;
$veryLightGreyBlue: #ECF0F4;
$verylightGrey: #F3F3F3;
$lightGrey: #dddddd;
$grey: #CCCCCC;
$darkGrey: #aaaaaa;
$skyBlue: #CFE6EE;
$mediumBlue: #2B6094;
$darkBlue: #25408F;
$darkerBlue: #182969;
$royalBlue: #0C2666;
$greyDarkBlue: #202C36;
$grayBlue: #2A3B4A;
$turquoise: #04C4D1;
$offBlue: #4F95BB;
$lighterBlue: #00BCFF;
$waterBlue: #4dc7e4;
$green: #109110;
$lightGreen: #58a058;
$pink: #ff007a;
$darkPink: #ff007a;
$red: #D90000;
$orange: #e76565;
$lightOrange: #f6871f;
$warningOrange: #f8881f;
$lighterOrange: #f19d4e;
$silver: #A7B1C2;
$darkSilver: #7a7a7a;
$greyBlue: #607d8b;
$brown: #58595b;
$darkBrown: #555555;
$coal: #444444;
$darkCoal: #333333;
$lightBlack: #222222;
$darkBlack: #111111;
$black: #000000;
$lightPurple: #9579ac;
$concrete: #dee2e6;

//Background colors
$primary-color: #00B5DC;
$secondary-color: #00AC8E;
$tertiary-color: #00D3AF;

$text-color: #191919;
$text-color-negative: #AD1313;
$text-color-positive: #00AC8E;
$text-color-headings: #004A56;
$text-color-highlight: #00B5DC;
$grid-color-highlight: #1136A8;
$grid-color-tooltip: #00856e;

$header-main-background: #004A56;
$breadcrumb-color: mix($secondary-color, $verylightGrey, 10%);

$highlight-color-1: mix($tertiary-color, $verylightGrey, 10%);

$header-sub-padding: 1rem 2rem;
$header-sub-text-opacity: 1;
$header-sub-alignment: center left;
$suppress-header-text: false;

$chart-color-1: $tertiary-color;
$chart-color-2: $secondary-color;
$chart-color-3: $text-color-headings;

$profit-color: #00AC8E;
$loss-color: #C82525;

// override bootstrap 4 variables
$bs-primary: $primary-color;
$bs-secondary: #909090;
$bs-success: #109110;
$bs-danger: #D90000;
$bs-warning: #ffc107;
$bs-info: #00B0F0;
$bs-light: #ECF0F4;
$bs-dark: #343a40;

// Theme Colours &  override bootstrap 4 variables
$theme-colors: (
  "primary": $bs-primary,
  "secondary": $bs-secondary,
  "success": $bs-success,
  "danger": $bs-danger,
  "warning": $bs-warning,
  "info": $bs-info,
  "light": $bs-light,
  "dark": $bs-dark,
);

$alert-bg-level: -9.5;
$alert-border-level:-8;
$alert-color-level: 7;

$form-border: #ced4da;
$form-widget-color: #e9ecef;
$form-focused-border: #419de5;
$form-focused-shadow-color: rgba(20, 91, 146, 0.25);
$form-focused-shadow: 0 0 0 0.2rem $form-focused-shadow-color;

// Validation colours
$validation-color-error: $bs-danger;
$validation-color-warning: #D66500;
$validation-color-info: #117a8b;

$validation-border-error: $validation-color-error;
$validation-shadow-error-color: rgba(220,53,69,.25);
$validation-shadow-error: 0 0 0 0.2rem $validation-shadow-error-color;

$validation-border-warning: $validation-color-warning;
$validation-shadow-warning-color: rgba(214, 101, 0, .25);
$validation-shadow-warning: 0 0 0 0.2rem $validation-shadow-warning-color;

$validation-border-info: $validation-color-info;
$validation-shadow-info: 0 0 0 0.2rem rgba(17, 122, 139, .25);

// Gradients on buttons
$enable-gradients: true;

// Shadows on buttons / modals
$enable-shadows: false;


// Slightly modifies the xl breakpoint by changing the max-width from 1140 to 1160 (to cater for larger padding).
// Adds an xxl breakpoint for full HD screens.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px,
  xxl: 1700px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl: 1500px
);