@import "../../../App/Styles/Variable/Variables.scss";

.approval-summary {
  &.selectable {
    cursor: pointer;
  }

  span.danger {
    color: $bs-danger;
  }

  .info-row {
    div {
      display: inline-block;

      > :first-child {
        margin-right: 15px;
      }
    }
  }

  .options {
    div {
      display: inline-block;
    }
    ul {
      padding-left: 20px;
      display: inline-block;
      > li {
        display: inline-block;
        padding-right: 15px;
      }
    }
  }

  .stats {
    div {
      div {
        display: inline-block;
      }
      > :first-child {
        min-width: 80px;
      }
    }
    .approved {
      strong {
        color: $bs-success;
      }
      i {
        color: $bs-success;
      }
    }
    .declined {
      strong {
        color: $bs-danger;
      }
      i {
        color: $bs-danger;
      }
    }
  }
}

.approvals {
  .allocation-totals {
    border-bottom: #ddd 1px solid;
    padding-bottom: 3px;
    font-size: large;
    font-weight: 400;
    .allocation-total {
      text-align: right;

      .symbol {
        font-weight: 600;
      }
    }
  }

  .btn-group {
    .dropdown-item {
      i.fa-thumbs-up {
        color: $bs-success;
      }
      i.fa-thumbs-down {
        color: $bs-danger;
      }
      i.fa-eraser {
        color: $bs-warning;
      }
    }
  }

  .approvals-grid {
    th.approve-col {
      text-align: center;
    }
    td.approve-col {
      text-align: center;
    }

    .loader-container.small {
      font-size: unset;
    }

    .declined-reason {
      margin-left: 13px;
    }
  }

  .approval-change-loader {
    margin-top: 0px;
  }
}
