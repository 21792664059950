@import "../../../App/Styles/Variable/Variables.scss";

.short-guides {
    margin-left: -10%;
}

.date-color {
    color: $primary-color;
}

.short-guide-image {
    width: 90%;
}

.support-image {
    width: 80%;
}

.header-small {
    color: $text-color-headings;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: -0.015em;
    font-size: larger;
}

.request-modal .modal-footer {
    border-top: none;
    padding: 15px;
}

.text-color-positive {
    color: $text-color-positive;
}

.text-color-negative {
    color: $text-color-negative;
}

.arrow-right {
    margin-left: -4%;
    margin-right: 2%;
}

.arrow-down {
    margin-left: -4%;
    margin-right: 2%;
}

.special-page-block {
    margin-top: -3%;
    background-color: #fff;
}

.special-page-block-alt {
    margin-top: -3%;
}

table.neo-grid table tr td.last-allocation {
    border-top: none;
    padding-top: 0px;
    padding-bottom: 0px;
    text-align: left;
}

.am-table {
    .form-control:disabled,
    .form-control[readonly],
    .input {
        background: transparent;
        border: none;

        &:focus {
            border-color: #fff;
            box-shadow: 0px 1px 1px #fff inset, 0px 0px 8px #fff;
        }
    }
}

.budget-table-row {
    background: linear-gradient(270deg, #00d3af 44.62%, rgba(255, 255, 255, 0) 84.11%), #00856e;
    color: white;
    padding: 0.5rem 0;

    .budget-table-header {
        text-align: right;
        font-weight: 700;
    }
}

table.layout-table {
    width: 100%;

    tr td {
        padding: 0;
        border: none;
    }
}
table.layout-table-padded {
    width: 100%;

    tr td {
        padding: 4px 7px;
        border: none;
    }
}

table.table.allocation-participants {
    > thead th {
        background-color: white;
        border-bottom: solid 1px #d2dfe1;
        font-weight: 700;
    }

    th.cell-highlight,
    td.cell-highlight {
        color: $grid-color-highlight;
    }

    .text-smaller {
        font-size: 13px;
        line-height: 15px;
    }

    table.allocation-scheme-values {
        .form-group {
            margin-bottom: 0.25rem;

            &.highlight {
                .form-control,
                .input-group-text {
                    color: $grid-color-highlight;
                }
                .input-group-prepend {
                    .input-group-text {
                    font-size: 12px;
                    }
                }
            }

            .input-group-text {
                padding: 0;
                width: 50px;
                justify-content: center;
            }
            .form-control {
                height: auto;
                padding: 0 0.5rem;
                text-align: right;
            }
        }
    }
}

table.table.allocation-linked-managers {
    > tbody > tr > td {
        padding: 10px 7px;
    }
}

.bordered-value {
    border: 1px solid #ced4da;
    margin-left: 10px;
    .form-control {
        border: 1px solid #ced4da !important;
    }
}

.bordered-value-alt .input-group-text {
    border: none !important;
    background: transparent;
}

td.am-editable-column {
    cursor: pointer;
    color: $grid-color-highlight;
    font-weight: 400;

    &:hover {
        background-color: #ebf8f6;
        transition: 1s;
    }
}

.am-totals {
    background: linear-gradient(270deg, #00d3af 44.62%, rgba(255, 255, 255, 0) 84.11%), #00856e;
    color: white;
    border: none;
    outline: none;
    font-weight: bold;
    font-family: Montserrat, Calibri, "Helvetica Neue", "Lucida Grande", "Segoe UI", Arial, Helvetica, Verdana,
        sans-serif;
}

.am-totals td {
    height: 20px;
    border: none;
    outline: none;
}

.accordion-button {
    border: none;
    font-family: "Neuzeit Grotesk", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
    font-weight: 400;
    color: #191919;
    text-align: left;
    font-size: 1rem;
    line-height: 1.5;
    width: 100%;

    &:focus {
        outline: transparent;
    }
}

.accordion-page-block {
    background-color: #fff;
}

.accordion-page-block {
    background-color: #fff;
}

.am-linked-manager-bg {
    background-color: #c8ede6;
}

.am-linked-managers {
    .neo-grid {
        .neo-child-row td.neo-child-row-cell {
            padding: 0;
        }
    }

    .neo-child-row-cell th {
        visibility: visible;
    }

    table.table tr.neo-child-row {
        background: #fff;
        border: none;

        > td {
            border: none;
        }
    }
}

//TODO: the image that gets used for the exanding of the child row needs to be an arrow right and arrow down
// .am-linked-managers {
//     .neo-grid .neo-grid-expand-column .btn.btn-light.btn-sm{
//         background-color: red;
//         content: "\f105";
//     }
//     .neo-grid .neo-grid-expand-column .btn.btn-light.btn-sm ::after{
//         background-color: green;
//         content: "\f107";
//     }
// }

.am-history-table.table.neo-grid > tfoot {
    background: linear-gradient(270deg, #00d3af 44.62%, rgba(255, 255, 255, 0) 84.11%), #00856e;
    color: white;
    border: none;
    outline: none;
    font-weight: bold;
    font-family: Montserrat, Calibri, "Helvetica Neue", "Lucida Grande", "Segoe UI", Arial, Helvetica, Verdana,
        sans-serif;
}

.am-history-table.table.neo-grid > thead th {
    background-color: transparent;
}

.am-history-table.table > tfoot th {
    background-color: transparent;
}

span.no-wrap-xl {
    white-space: normal;
}

@media (min-width: 1700px) {
    span.no-wrap-xl {
        white-space: nowrap;
    }
}

table.neo-grid .col-align-center input,
.css-yk16xz-control {
    border: none !important;
    border-color: transparent !important;
}

.can-edit-wording {
    font-size: 19px;
    color: $primary-color;
}

.review-button {
    display: flow-root;
}

.budget-margin {
    margin-left: 4px;
    margin-right: 4px;
    text-align: right;
}

.show-white-space {
    white-space: pre-wrap;
}

.am-perc-of-salary {
    text-align: right;
    background-color: white;
    color: $text-color-headings;
}