@import "../../../App/Styles/Variable/Variables.scss";

.proxy-participant {
    cursor: pointer;
    color: $bs-dark;
    border: solid 1px lightgray;
    padding: 5px;
    margin-bottom: 15px;

    display: flex;
    flex-direction: column;

    .initials-container {
        margin: 5px auto;

        .initials {
            font-size: large;
            font-weight: 600;
            padding: 5px;
            width: 50px;
            height: 50px;
            border: solid 1px $bs-info;
            border-radius: 50%;

            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            margin: 5px;
        }
    }

    .name-container {

        .name {
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            min-height: 50px;
        }
    }
}