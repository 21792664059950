@import "../../../App/Styles/Variable/Variables.scss";

.accept-awards {
    .col.page-holder.right-tools {
        // TODO: cant get this css to apply to the page-holder :(
        padding-right: 0px !important;
    }

    .awards-grid {
        thead {
            tr {
                th.select-percent {
                    padding-right: 14px;
                }
            }
        }
        tbody {
            tr {
                td {
                    &.document-buttons {
                        .btn-group {
                            width: 100%;
                            padding: 0px;
                            margin: 0px;
                        }
                        .btn {
                            margin: 5px 5px 5px 8px;
                            display: block;

                            &.small {
                            }

                            &.large {
                                width: 100%;
                                min-width: 150px;
                            }
                        }
                    }

                    i.action-icon {
                        padding: 5px;
                    }
                }
            }
        }
    }

    .template-html-field {
        table {
            thead {
                tr {
                    th {
                        padding: 5px;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding: 5px;
                    }
                }
            }
        }
    }

    .accept-awards-footer {
        padding-top: 5px;
        .accept-ts-and-cs {
            text-align: right;

            .form-group {
                label {
                    font-size: 1.2rem;
                    font-weight: 400;
                }
            }

            display: flex;

            padding-top: 10px;
            padding-right: 10px;
            font-size: 1.1rem;

            .large-check-box {
                flex-flow: column;
                margin-left: auto;
            }
        }

        .accept-buttons {
            padding: 5px 10px 5px 10px;
            display: grid;
            grid-auto-flow: column;

            .btn {
                min-width: 130px;
            }
        }
    }

    .my-award-document {
        div.award-document-header {
            margin-bottom: 15px;
        }

        .award-document-response {
            font-size: 1.2rem;

            div.file-upload-info {
                margin-left: 15px;
                i {
                    margin-right: 5px;
                }
            }

            div.large-check-box {
                font-size: 20px;
                margin-right: 5px;
                margin-left: 5px;
            }

            &.accepted {
                color: var(--primary);
            }

            &.declined {
                color: var(--danger);
            }
        }
    }
}

// TODO: How can I use the BS small breakpoint?
@media (max-width: 768px) {
    .accept-awards {
        .accept-awards-footer {
            .accept-ts-and-cs {
                text-align: left;
                margin-left: 35px;
                                
                .custom-control {
                    padding-top: 5px;
                    padding-left: 0px;
                }
            }            

            .accept-buttons {
                .btn {
                    min-width: 80px;
                }
            }
        }
    }
}
