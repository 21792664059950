.documents-view {
    table.table {
        th {
            padding: 15px 7px;
        }
        td {
            padding: 5px 7px;
        }

        .neo-grid-button-column {
            padding-right: 0;
        }
    }
}

.form-group.no-border {
    margin-bottom: 2rem;
    position: relative;

    .form-control {
        border: none;
        padding: 0.25rem 0;
        height: auto;
    }
    .right-label {
        position: absolute;
        top: 0;
        right: 0;
    }
}